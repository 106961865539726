.sidebar-admin {
    background: #021849;
    min-height: 100vh;
    position: fixed;
    z-index: 100;
    width: 280px;
}
.sidebar-admin li, .sidebar-admin-mobile li{
	list-style: none;
    margin-bottom: 0.5rem;
}
.sidebar-admin li:hover, .sidebar-admin-mobile li:hover, .active-admin-item{
    background-color: #ffffff;
    border-radius: 10px;
}

.sidebar-admin li:hover .sidebar-admin-link, .active-admin-link, .sidebar-admin-mobile li:hover .sidebar-admin-link {
    color: #000000 !important;
}

.sidebar-admin-link {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 1rem;
    padding: 0.7rem;
}
.sidebar-icon-container {
    display: flex;
    align-items: center;
    font-size: 20px; 
    line-height: 21px;
}


