.banniere-component {
  background: #e6e8ed;
}
.banniere-container {
  padding-left: 5%;
  padding-right: 3%;
  padding-top: 7%;
  padding-bottom: 3%;
}
.titre-banniere {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #171717;
}
.bloc-btn-banniere {
  margin-top: 8rem;
}
.bloc-img-dots {
  position: absolute;
  top: 56px;
}
.why-maajjal-section {
  padding: 7% 0;
  background: #fbfbfb;
}
.bloc-entete-about {
  display: flex;
}
.icon-container {
  background: linear-gradient(0deg, #f8f9fd, #f8f9fd), #e8f2ff;
  border-radius: 22.2807px;
  width: 71.3px;
  height: 71.3px;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}
.chiffre-position-item-about {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: 900;
  font-size: 227.263px;
  line-height: 227px;
  letter-spacing: -0.02em;
  color: #000000;
  opacity: 0.05;
}
.titre-about-maajjal-position-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  font-size: 17.8246px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #000000;
}
.texte-about-maajjal-position-item {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 15.5965px;
  line-height: 27px;
  letter-spacing: -0.02em;
  color: #757095;
  display: block;
  text-overflow: Ellipsis;
  word-wrap: break-Word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}
.about-maajjal-component {
  background: #ffffff;
  border-radius: 5px;
  padding: 30px;
  position: relative;
  box-shadow: 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
  width: 80%;
}
.chiffre-about-container {
  position: absolute;
  right: 1%;
  top: -6%;
}
.projet-en-cours-campagne-section {
  padding: 7% 0;
}
.texte-display-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.produit-marketplace-item-component {
  background: #ffffff;
  box-shadow: 0px 24px 32px -3px rgb(3 9 50 / 4%);
  border-radius: 22.6091px;
  padding: 4%;
  margin: 3%;
}
.produit-marketplace-item-component:hover {
  background: #ffffff;
  box-shadow: 0px 11.3046px 120px rgba(2, 24, 73, 0.05);
  border-radius: 16.72px;
  transform: scale(1.08);
}
.texte-reduction-top {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13.5655px;
  line-height: 20px;
  color: #fff;
  background: #0fbf56;
  padding: 2px;
  border-radius: 5px;
}
.texte-reduction {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13.5655px;
  line-height: 20px;
  color: #0fbf56;
}
.titre-produit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 23.4084px;
  line-height: 24px;
  color: #5070b8;
}
.text-description-produit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
  display: block;
  text-overflow: Ellipsis;
  word-wrap: break-Word;
  overflow: hidden;
  max-height: 2em;
  line-height: 1.8em;
}
.btn-ajouter-produit-panier {
  background: #f9f9f9;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  width: 100%;
  border: none;
  padding: 0.7rem;
}
.card {
  border: none !important;
}
.titre-section-why-maajjal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  letter-spacing: -0.025em;
  color: #000000;
}
.react-multiple-maajjal-carousel__arrow {
  display: none;
}
.react-multi-card-carousel-dot button {
  border-color: #dadaee !important;
  border-width: 0 !important;
  width: 16px !important;
  height: 16px !important;
}
.card-testimonial-item {
  background: #ffffff;
  box-shadow: 0px 24px 32px -3px rgba(3, 9, 50, 0.04);
  border-radius: 32px;
  padding: 4%;
  box-sizing: border-box;
}
.test-testimonial {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #031432;
  display: block;
  text-overflow: Ellipsis;
  word-wrap: break-Word;
  overflow: hidden;
  max-height: 6.6em;
  line-height: 1.8em;
  min-height: 150px;
}
.bloc-img-testimonial {
  display: flex;
  align-items: center;
}
.img-testimonial {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.nom-partner-testimonial {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3a8ef6;
}
.profession-partner-testimonial {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  color: #6c87ae;
}
.carousel-produit-marketplace .card-img-top {
  height: 200px;
}
.react-multi-maajjal-carousel-track {
  padding-bottom: 100px !important;
}
img.icon-about-maajjal {
  width: 36.79px;
  height: 36.79px;
}
.product-img {
  height: 200px;
}
.custom-card-img-top {
  border-radius: 20px;
}
.texte-reduction-container {
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 1025px) {
  .custom-card-img-top {
    height: 218px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .custom-card-img-top {
    height: 172px;
  }
}
@media (min-width: 426px) and (max-width: 768px) {
  .custom-card-img-top {
    height: 131px;
  }
}

@media (max-width: 320px) {
  .custom-card-img-top {
    height: 172px;
  }
}
@media (min-width: 321px) and (max-width: 375px) {
  .custom-card-img-top {
    height: 205px;
  }
}
@media (min-width: 376px) and (max-width: 425px) {
  .custom-card-img-top {
    height: 235px;
  }
}
