.admin-commande-detail-produit-information-nom-container {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
}

.dashboard-commande-detail-produit-information-nom2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
}
.admin-commande-detail-produi-title {
    margin-top: 30px;
}

@media (max-width: 768px) {
    .dashboard-commande-detail-produit-quantite-container, .dashboard-commande-detail-produit-quantite-container2 {
        display: flex;
        gap: 60px;
        margin-bottom: 25px;
        padding-left: 30px;
    }
    .dashboard-commande-detail-produit-quantite-container2 {
        padding-left: 67px;
    }
    .dashboard-commande-detail-container {
        padding: 10px;
    }
    .dashboard-commande-detail-produit-information-nom2,
    .dashboard-commande-detail-produit-information-nom,
    .dashboard-commande-detail-produit-information-nom1 {
        font-size: 12px;
    }
    .dashboard-commande-detail-produi-title,
    .dashboard-commande-detail-produit-quantite2,
    .dashboard-commande-detail-produit-quantite {
        font-size: 14px;
    }
    
}