.m-tableau-bord {
	margin: 15px;
}
.user-name {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 40px;
	letter-spacing: -0.025em;
	color: #000000;
}
.register-date {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 25px;
	letter-spacing: -0.025em;
	color: #000000;
}
.profile-form label {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #1a1a1a;
	margin-bottom: 5px;
}
.form-profile-select {
	background: #ffffff;
	border: 2px solid #e1e1e1;
	border-radius: 10px;
	width: 100%;
	padding: 15px;
	appearance: none;
}
.form-profile-input-group {
	position: relative;
}
.form-profile-control {
	background: #ffffff;
    border: 2px solid #e1e1e1;
    border-radius: 10px;
    width: 100%;
    appearance: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 15px;
}
.icon-edit-container {
	position: absolute;
	top: 22%;
	right: 5px;
}
.btn-profile-submit {
	background: #0fbf56;
	border: 2px solid #0fbf56;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	padding: 0.8rem 5rem;
}
.title-change-password {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #021134;
}
.mon-compte-container {
	padding-left: 8rem;
	padding-right: 8rem;
}
.form-row {
	display: flex;
	gap: 2rem;
	margin-bottom: 1rem;
}
.w-custom {
	width: 46%;
}