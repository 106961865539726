.admin-utilisateur-table-btn-action {
    display: flex;
    gap: 10px;
}

.maj-admin-infos-utilisateur-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.maj-admin-infos-utilisateur-img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
}
.maj-admin-infos-utilisateur-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-top: 10px;
}

.mad-donnateur-carte-identite-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: rgba(150, 150, 156, 0.9);
    display: flex;
    border-bottom: 1.5px solid #ECECEC;
    padding-bottom: 15px;
}

.maj-admin-infos-utilisateur-identite-img-container {
    display: flex;
    gap: 20px;
}
.maj-admin-infos-utilisateur-identite-img {
    max-width: 300px;
    height: auto;
}

@media (max-width: 768px) {

    .maj-admin-infos-utilisateur-identite-img-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

}