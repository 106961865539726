.contactez-nous-title {
	font-family: "Poppins";
	font-style: normal;
	font-size: 24px;
	font-weight: 600;
	line-height: 36px;
	text-align: center;
	color: #021849;
}
.form-contact-label {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.025em;
	color: rgba(0, 0, 0, 0.7);
	margin-bottom: 10px;
}

.form-contact-textarea,
.form-contact-input {
	border: 0.25px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.025em;
}

.form-contact-input {
	height: 60px;
}

.form-contact-textarea {
	height: 220px;
}

.form-contact-input::placeholder,
.form-contact-textarea::placeholder {
	font-weight: 700;
	color: rgba(0, 0, 0, 0.5);
}
.form-contact-btn {
	background: #0fbf56;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
	border: none;
}
