/******************** flex utilities **********************/
.flex-sb {
  display: flex;
  justify-content: space-between;
}
.social-auth > span {
  width: 100%;
}

.flex-r {
  display: flex;
  justify-content: flex-end;
}
.flex-c {
  display: flex;
  justify-content: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.item-c {
  display: flex;
  align-items: center;
}
.flex-cc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-csb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-rc {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}
.flex-col-item-c {
  display: flex;
  align-items: center;
  flex-direction: column;
}
/*****************Opacities utilities*****************/
.op-0 {
  opacity: 0 !important;
}

/*****************Line-Heigt utilities*****************/
.leading-16 {
  line-height: 16px;
}
.leading-21 {
  line-height: 21px;
}
/*****************font-size utilities*****************/
.text-10 {
  font-size: 10px;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 14px;
}
.text-15 {
  font-size: 15px;
}

/*****************font-weigth utilities*****************/
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
/************* border utilities ******/
.divide-maajjal {
  background: rgba(0, 0, 0, 0.7);
}
.border-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

/************* border utilities ******/
.line-through {
  text-decoration: line-through;
}

.pt-140 {
  padding-top: 140px;
}

/* ============== custom btn swal ================ */

/* .swal2-styled {
	background-color: #021849 !important;
} */

#scrollbar-nav {
  height: 100%;
  overflow-y: auto;
}
#scrollbar-nav::-webkit-scrollbar {
  display: none;
}
.content-nav {
  height: 350px;
  /* height: 90px; */
}

.form-check-input:checked {
  background-color: #021849;
  border-color: #021849;
}

@media (max-width: 767px) {
  .content-nav {
    /* height: 350px; */
    height: 90px;
  }
}
