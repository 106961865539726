.modal-header-investissement {
    background: #021849;
    border-radius: 10px 10px 0px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.025em;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-investissement-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.8);
}
.modal-investissement-description2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.5);
}
.modal-investissement-description-content2 {
    width: 80%;
    margin: auto;
}
.modal-investissement-description-content {
    padding: 0px 40px;
}
.modal-investissement-description3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #000000;
    margin: 15px 0px 20px 0px;
}

.modal-investissement-montants {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
}
.modal-investissement-montants-radio-label {
    display: flex;
    align-items: center;
    gap: 5px;
}
.modal-investissement-montants-btn-annuler {
    border: 1px solid #0FBF56;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #0FBF56;
    padding: 10px 25px;
}
.modal-investissement-montants-btn-annuler:hover {
    border: 1px solid #0FBF56;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    padding: 10px 25px;
    background: #0FBF56;
}
.modal-investissement-montants-btn-continuer {
    background: #0FBF56;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 10px 25px;
}
.modal-investissement-montants-btn-continuer:hover {
    border: 1px solid #0fbf56;
	color: #0fbf56;
	background: #fff;
	text-decoration: none;
}
.modal-investissement-montants-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.modal-investissement-montants-input {
    margin: 20px 0px;
}
.modal-invessetissement-body {
    padding: 20px 40px 50px 40px;
}

.modal-investissement-description-mode-paie {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14.6949px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.8);
    margin: 30px 0px;
}

.modal-investissement-mode-paie {
    display: flex;
    justify-content: space-between;
    margin: 40px 0px 70px 0px;
    gap: 15px;
    overflow: auto;
}
.modal-investissement-mode-paie::-webkit-scrollbar {
    display: none;
}

.modal-investissement-detail-paie {
    display: flex;
    flex-direction: column;
}
.modal-investissement-card-paie-container {
    display: flex;
    align-items: center;
    gap: 30px;
    margin: 10px 0px;
}
.card-img-detail-paie {
    max-width: 100%;
    /* height: auto; */
    width: 120px;
    height: 80px;
}

.modal-investissement-description-detai-paie {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #000000;
    margin: 20px 0px;
}

.custom-modal-investissement {
    border-radius: 10px;
    /* width: 100%; */
}

.modal-investissement-confirm-para-container {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
    margin: 30px 0px 50px 0px;
}
.modal-investissement-confirm-para-span {
    font-weight: 800;
}

.modal-header-investissement-confirm {
    border: none;
}

.modal-investissement-description-confirm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 51px;
    text-align: center;
    color: #000000;
}
.modal-investissement-confirm-btn-annuler {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}
.modal-investissement-confirm-btn-annuler:hover {
    border: 1px solid #000000
}



@media (max-width: 768px) {
    .modal-investissement-description-content2 {
        width: 100%;
        margin: auto;
    }
    .modal-invessetissement-body {
        padding: 20px 10px 50px 10px;
    }
    .modal-investissement-description-mode-paie,
    .modal-investissement-description-detai-paie {
        font-size: 24px;
        line-height: 36px;
    }
    .custom-modal-investissement {
        border-radius: 10px;
        width: 100%;
    }
}
.form-control-contribution-steps {
    border: 1px solid #C4C4C4;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12.8581px;
    line-height: 19px;
    color: #8E8EA1;
    padding: 20px;
}