footer{
    background: #0A2A42;
}

.body-footer{
    padding-top: 3rem;
}
.end-footer{
    border-top: 1px solid #5A5A5A;
    display: flex;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    background: #0A2A42;
}

footer .logo{
    width: 160px;
}

.text-footer-span {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #fff;

}
.link-top-footer:hover {
    color: #BB4411;
    border: #BB4411;
    background-color: #fff;
    text-decoration: none;
}
.footer-grid .title-footer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

.text-footer {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.204545px;
    color: #fff;
}
.text-footer:hover {
    text-decoration: none;
    color: #fff;
}
.icon-social-footer {
    width: 39px;
    height: 39px;
}
.footer-grid .text-content{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    color: #FFFFFF !important;
}
.footer-grid .link-footer{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 169%;
    text-align: justify;
    letter-spacing: 0.05em;
    color: #fff !important;
    text-decoration: none;
}
.footer-grid .link-footer:hover{
    text-decoration: none;
}
.footer-grid .contact-text{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #FFFFFF !important;
    text-align: left;
}
.footer-grid .contact-text:hover{
    color: #FFFFFF !important;
    text-decoration: none;
}
.footer-grid .contact-text>svg{
    width: 14px;
    position: absolute;
    left: 15px;
}

.social-networking a {
    padding-left: .5rem;
    padding-right: .5rem;
}
.social-networking span {
    padding-left: 2rem;
    padding-right: 2rem;
}
.social-networking a:hover{
    transform: scale(1.2);
    opacity: 1;
}
.social-networking img{
    width: 100%;
}

footer .copyright{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    display: inline-block;
}
.container-maajjal-footer {
    padding-left: 6rem;
    padding-right: 6rem;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
}
.title-footer-contact {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150.4%;
    letter-spacing: -0.204545px;
    color: #fff;
}
.link-footer-navigation {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-decoration: none;
}
.link-footer-navigation:hover {
    font-weight: 700;
    color: #fff !important;
}
.link-footer-cgu {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #FFFFFF;
    text-decoration: none;
}
.link-footer-cgu:hover {
    color: #fff !important;
    font-weight: 600;
}
.link-footer-nous-contacter {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #FFFFFF;
    background: #0FBF56;
    border-radius: 10px;
    padding: .9rem;
    text-decoration: none;
}
.link-footer-nous-contacter:hover {
    color: #0FBF56;
    background: #fff;
    border: 1px solid #0FBF56;
}