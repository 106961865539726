.mt-tableau-bord-detail-projet {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 30px;
}

.supprimer-btn-ajout {
    background: none;
    border: none;
    color: #B7B1B1;
    width: 15px !important;
    height: 15px !important;
}

.details-projet-tab-container-admin {
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.02);
    margin-top: 0px;
    padding: 20px 0px 0px 0px;
    display: flex;
    justify-content: space-between;

}


@media (max-width: 768px) {
    .details-projet-tab-container-admin {
        overflow: auto;
    }
    .details-projet-tab-container-admin::-webkit-scrollbar {
        display: none;
    } 
}