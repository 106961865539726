.top-bar-admin {
	width: 100% !important;
	position: fixed;
	top: 0;
	z-index: 10;
}
.parametre-page {
	overflow-x: hidden;
}

.auth-user-container {
	display: flex;
	align-items: center;
    flex-shrink: 1;
}
.auth-user-image {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.auth-user-name {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 15px;
	color: #22242c;
}
.icon-notif-container {
	width: 40px;
	height: 40px;
	border: 1.35826px solid #8e8ea1;
	border-radius: 27.1651px;
	background-color: transparent;
	position: relative;
	display: flex;
    align-items: center;
    justify-content: center;
}
.dot-indicatif {
	background: #021849;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	position: absolute;
	top: 0;
	right: 0;
}
.custom-dropdown-item {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #000000;
    border-bottom: 1px solid rgba(196, 196, 196, 0.2);
}
.top-bar-menu {
    background-color: transparent;
    border: none;
    color: #22242c;
    font-size: 20px;
}
.menu-mobile {
    background: #021849;
}

.dropdown-btn-logged-user-name-admin {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-size: 12.2243px;
	line-height: 15px;
	color: #22242C;
}