.maajaal-admin-dashboard-card1,
.maajaal-admin-dashboard-card2,
.maajaal-admin-dashboard-card3, 
.maajaal-admin-dashboard-card4, 
.maajaal-admin-dashboard-card5 {
    min-width: 240px;
    height: 143px;
    border-radius: 10px;
    color: #FFFFFF;
    position: relative;
    margin-bottom: 20px;
}
.maajaal-admin-dashboard-card1 {
    background: #19243D;
}

.maajaal-admin-dashboard-card2 {
    background: #3B4B6F;
}
.maajaal-admin-dashboard-card3 {
    background: #021849;
}
.maajaal-admin-dashboard-card4 {
    background: #546DA5;
}
.maajaal-admin-dashboard-card5 {
    background: #082C7C;
}
.ma-dashboard-card-icon {
    position: absolute;
    top: 15px;
    right: 25px;
}
.ma-dashboard-card-icon-wallet {
    width: 30px;
    height: 30px;
}
.ma-dashboard-card-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}
.ma-dashboard-card-subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
}
.ma-dashboard-card-price {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    color: #FFFFFF;
}

.ma-dashboard-card-price-span {
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
}
.ma-dashboard-card-title-container {
    margin-bottom: 40px;
}
/* .maajaal-admin-dashboard-card-container {
} */
.ma-dashboard-table-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ma-dashboard-table-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #3B4B6F;
    
}
.ma-dashboard-table-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #3B4B6E;
}

.voir-btn-offre {
	color: #404040;
	width: 18px;
	height: auto;
}

@media (min-width: 1024px) and (max-width: 1439px) {
    .ma-dashboard-card-title {
        font-size: 12px;
    }
    .maajaal-admin-dashboard-card1, .maajaal-admin-dashboard-card2, .maajaal-admin-dashboard-card3, .maajaal-admin-dashboard-card4, .maajaal-admin-dashboard-card5 {
        min-width: 228px
    }
    .ma-dashboard-card-price {
        font-size: 30px;
    }
    .ma-dashboard-card-icon-wallet-custom {
        width: 28px;
        height: 28px;
    }
}