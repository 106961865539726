.campagne-page-content {
    padding: 140px 50px 50px 50px;
    height: auto;
    min-height: 100vh;
}

.campagne-filter-boutton-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    overflow: auto;
}
.campagne-filter-boutton-container::-webkit-scrollbar {
    display: none;
}

.card-campagne-progresse-container {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #0FBF56;
    text-align: end;
}

.card-campagne-progresse-details {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    display: flex;
    justify-content: space-between;
}

.card-campagne-button-share-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-campagne-button-share-like {
    display: flex;
    gap: 15px;
}

.campagne-categorie-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #838181;
    margin-bottom: 0px;
}
.campagne-filter-boutton {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #6F6F6F;
    border: 1px solid #6F6F6F;
    border-radius: 40px;
}
.campagne-filter-boutton:hover, .campagne-filter-boutton-selected {
    background: #0FBF56;
    border: 1px solid #0FBF56;
    color: #FFFFFF;
}

.card-campagne-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.665612px;
    color: #000000;
}

.campagne-button-contribuer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    color: #0FBF56;
    border: 1px solid #cfffe4ef;
    border-radius: 10px;
    padding: 11px;
}
.campagne-button-contribuer:hover {
    background: #0fbf56;
	font-weight: 500;
	color: #ffffff;
	text-decoration: none;
}
.campagne-button-share-like {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6.65612px 9.98418px;
    gap: 6.66px;
    background: #E8E8E8;
    border-radius: 3.32806px;
}
.campagne-button-share-like:hover {
    background: #d5d5d5;
}

.custom-card-campagne {
    border-radius: 20px;
    background: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    margin-bottom: 50px;
}

.card-campagne-icons {
    color: #000000;
    width: 16px;
    height: 16px;
    font-weight: 800 !important;
}
/* .campagne-search-bar-container {
    padding: 5px 0px;
} */

.campagne-search-bar {
    box-sizing: border-box;
    width: 362px;
    height: 50px;
    background: #EEEEEE;
    border: 1px solid #EEEEEE;
    border-radius: 40px;
    padding-left: 50px;
}

.campagne-search-bar-container i {
    position: absolute;
    left: 20px;
    top: 18px;
}
  
.campagne-search-bar-container {
    /* width: 100%; */
    position: relative;
}

.custom-icon-search-mission {
    color: rgba(0, 0, 0, 0.53);
}

.custom-card-image-top {
    border-radius: 17px
}

/* ============================== RESPONSIVE =========================== */

@media (max-width: 768px) {
    .card-campagne-title {
        font-size: 15px;
    }
}
@media (max-width: 767px) {

    .campagne-search-bar {
        width: 100%;
    }

    .campagne-page-content {
        padding: 8em 10px 2em 10px;
    }

}
@media (min-width: 768px) and (max-width: 1023px) {

    .campagne-page-content {
        padding: 8em 10px 2em 10px;
    }

}