.margin-container {
	margin-top: 4rem;
	margin-bottom: 4rem;
}
.launch-camapaign-form-container {
	background: #ffffff;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
}
.btn-submit {
	padding: 0.8rem 5rem;
	left: 252px;
	top: 1857px;
	background: #0fbf56;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 34px;
	letter-spacing: 1px;
	color: #ffffff;
	border: none;
	box-shadow: 6px 31px 30px 0px rgba(0, 0, 0, 0.1);
	transition: ease all 1s;
}
.btn-submit:hover {
	background-color: #ffffff;
	color: #0fbf56;
	border: 1px solid #0fbf56;
}
.back-btn {
	background-color: transparent;
	border: none;
}
.launch-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	color: #404040;
}

.img-produit-placeholder {
	color: #868686;
}
