.my-cart-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 34px;
	letter-spacing: 1px;
	color: #000000;
}
.shopping-text {
	font-family: "Poppins";
	font-style: normal;
	line-height: 31px;
	color: #000000;
}
.sohpping-item-desc {
	font-family: "Poppins";
	font-style: normal;
	color: #343434;
}
.line-throught {
	text-decoration: line-through;
}
.cart-btn {
	padding: 0.9rem 1.3rem;
	border: 1px solid;
	border-radius: 7px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}
.cart-btn-shop-delete {
	background: #db0909;
	border-color: #db0909;
	color: #ffffff;
}
.cart-btn-shop-command {
	border: 1px solid #9d9696;
	background-color: #ffffff;
	color: #9d9696;
}
.btn-inc-dec-container {
	border: 1px solid #9d9696;
	border-radius: 10px;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.1em;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 120px !important;
}
.btn-inc-dec {
	background-color: transparent;
	padding: 5px 15px;
	border: none;
	color: #9d9696;
}
.number-item {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.1em;
	color: #089641;
}

.shopping-item-img {
	width: 230px;
}