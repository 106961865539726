.info-delevry-title {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0px;
	text-align: center;
	color: #5a5a5a;
}
.shopping-cart-modal {
	font-family: "Montserrat";
	font-style: normal;
	color: #000000;
}
.shop-cart-desc h5 {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
}
.shop-cart-desc h6 {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
}
.semi-total-shop {
	display: flex;
	justify-content: space-between;
}
.semi-total-shop h5 {
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
}
.semi-total-shop h6 {
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
}
.total-price-shop h5 {
	font-weight: 600;
	font-size: 29px;
	line-height: 18px;
}
.total-price-shop h6 {
	font-weight: 600;
	font-size: 26px;
	line-height: 32px;
	color: #0fbf56;
}
.btn-shop {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
}
.btn-shop-next {
	background: #0fbf56;
	border-radius: 10px;
	border: 1px solid #0fbf56;
	color: #ffffff;
	padding: 7px 1.5rem;
}
.btn-shop-cancel {
	color: #d90000;
	border: none;
	background-color: transparent;
}
.swal-message-style {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-size: 15px;
	line-height: 27px;
	text-align: center;
	color: #000000;
}
.mode-paiement-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 54px;
	text-align: center;
	color: #000000;
}
.form-check-input-majjal {
	width: 18px;
	height: 18px;
	border-color: #666666;
	margin-top: 8px;
}
.form-check-input-majjal:focus {
	background-color: #666666 !important;
}
.detail-success-paiement {
	background-color: #f7f8fa;
	border-radius: 10px;
}
.product-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	margin: 0;
}
.success-paiement-text h5{
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: rgba(0, 0, 0, 0.7);
}
.success-divide {
	margin: 0;
}