.etat-projet-en-ligne {
  background: #e9f3f4;
  border-radius: 100px;
  width: 65px;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #115c67;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.etat-projet-hors-ligne {
  background: #f00;
  border-radius: 100px;
  width: 65px;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.etat-projet-en-termine {
  background: #fbede7;
  border-radius: 100px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: -0.3px;
  color: #fe976b;
  width: 65px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.tb-mes-projet-titre {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 20px;
}

.table > :not(:first-child) {
  border-top: none;
}

.voir-btn {
  color: #979797;
  width: 18px !important;
  height: 18px !important;
}
.edit-btn {
  color: #979797;
  width: 18px !important;
  height: 18px !important;
}
.supprimer-btn {
  background: none;
  border: none;
  color: rgb(233, 88, 91);
  width: 15px !important;
  height: 15px !important;
}

.table-actions-btn-container-projet {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
