.dashboard-commandes-tab-container {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0px;
}

.dashboard-commandes-filtre-input {
  background: #f7f7f7;
  height: 39px;
  border-radius: 10px;
  border: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 17px;
  color: #252733;
}
.dashboard-commandes-filtre-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.dashboard-commandes-filtre-form {
  display: flex;
  align-items: center;
  gap: 20px;
  overflow: auto;
  flex-wrap: nowrap;
}
.dashboard-commandes-filtre-form::-webkit-scrollbar {
  display: none;
}

.etat-commande-non-valide {
  background: #db0909;
  border-radius: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.etat-commande-livrer {
  background: rgb(22, 160, 133);
  border-radius: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.etat-commande-valide {
  background: #55acee;
  border-radius: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.etat-commande-livre {
  background: #27c566;
  border-radius: 30px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  /* width: 65px;
    height: 24px; */
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.dashboard-commandes-icon-collapse {
  color: #6bd482;
  width: 20px;
  height: 20px;
}

.dashboard-commande-detail-produit-quantite-container,
.dashboard-commande-detail-produit-quantite-container2 {
  display: flex;
  gap: 100px;
  margin-bottom: 25px;
  padding-left: 90px;
}
.dashboard-commande-detail-produit-quantite-container2 {
  padding-left: 125px;
}
.dashboard-commande-detail-produit-quantite2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}
.dashboard-commande-detail-produi-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.dashboard-commande-detail-container {
  background: rgba(2, 24, 73, 0.03);
  border-radius: 10px;
  padding: 40px 100px;
}
.dashboard-commande-detail-produit-img {
  width: 110px;
  max-height: 150px;
}

.dashboard-commande-detail-produit-div {
  margin-bottom: 10px;
}
.dashboard-commande-detail-btn {
  background: #0fbf56;
  border: 1px solid #0fbf56;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}
.dashboard-commande-detail-btn-valide {
  background: #ffffff;
  border: 1px solid #0fbf56;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0fbf56;
}
.dashboard-commande-detail-btn-valide:hover {
  background: #0fbf56;
  border: 1px solid #0fbf56;
  color: #ffffff;
}

.dashboard-commande-detail-btn-cancel {
  background: #ffffff;
  border: 1px solid #c0392b;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #c0392b;
}
.dashboard-commande-detail-btn-cancel:hover {
  background: #c0392b;
  border: 1px solid #c0392b;
  color: #ffffff;
}

.dashboard-commande-detail-btn:hover {
  background: #ffffff;
  border: 1px solid #0fbf56;
  color: #0fbf56;
}

.dashboard-commande-detail-btn-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;
  gap: 20px;
  align-items: center;
}

.dashboard-commande-detail-produit-information-nom {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7);
}
.stepper-commandes-tab {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  background: none;
  border: none;
  padding: 20px 0px;
  padding-left: 10px;
  padding-right: 10px;
}
.stepper-active-commandes-tab {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0fbf56;
  background-color: #ffffff;
  border: none;
}

.dashboard-commande-detail-produit-information-nom1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7);
}
.dashboard-commande-detail-produit-information-nom2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7);
}

.dashboard-commande-detail-btn-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}
.dashboard-commandes-filtre-container {
  padding: 15px 0px;
}

.table-commande-pagination-btn {
  width: 50px;
  height: 50px;
  background: #0fbf56;
  border-radius: 10px;
  margin: 0px 5px;
}

.react-bs-table-sizePerPage-dropdown .btn-secondary {
  background: #fff !important;
  border: 1px solid #0fbf56 !important;
  color: #0fbf56 !important;
}
