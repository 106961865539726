.header-class {
    text-align: 'center';
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 700;
}

.react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }

  .progression-projet-format-plein,
  .progression-projet-format-restant {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #454545;

  }

  .toggle-style-off {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 9px;
    color: #FFFFFF;
    padding-right: 2px;
    padding-left: 2px;
    background-color: red;
  }
  .toggle-style-on {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 9px;
    color: #FFFFFF;
    padding-right: 2px;
    background-color: #21A374;
  }

  .admin-projets-btn-ajout-container {
      display: flex;
      justify-content: flex-end;
      margin-top: -20px;
      margin-bottom: -10px;
      padding-top: 0px;
      padding-bottom: 0px;
  }

  .admin-projets-btn-ajout {
    display: flex;
    align-items: center;
    gap: 3px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  .admin-projets-btn-ajout-icon {
      color: #021849;
      font-weight: 800;
      width: 22px;
      height: 22px;

  }

  .admin-ajout-projet-porteur-radio-container,
  .admin-ajout-projet-porteur-radio-container3
   {
      display: flex;
      gap: 40px;
  }
  .admin-ajout-projet-porteur-radio-container2 {
      display: flex;
      gap: 48px;
  }

  @media (max-width: 767px) {
    .admin-ajout-projet-porteur-radio-container
     {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-left: 15px;
    }
  }