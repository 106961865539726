.faq-card-item {
	background: #ffffff;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	font-family: "Montserrat";
	font-style: normal;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.faq-card-title {
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #021849;
}
.faq-publish-date {
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: rgba(0, 0, 0, 0.8);
	margin: 0;
}

.text-number-consultation-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	font-weight: 500;
	font-size: 25px;
	line-height: 30px;
	color: #5e5b5b;
}

.text-number-consultation-container .signe {
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	color: #5e5b5b;
}
.faq-info-action-container {
	display: flex;
	gap: 5rem;
	align-items: center;
}
.faq-action-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	background-color: #ffffff;
	border-radius: 50%;
	padding: 0.4rem;
	color: #c8c8c8;
	font-size: 20px;
}
.border-blue-dark {
	border: 1px solid #021849;
}
.border-red {
	border: 1px solid #bb4411;
}
.faq-add-btn {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #021849;
	background-color: #ffffff;
	border: 1px solid #021849;
	border-radius: 10px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    transition: all ease 400ms;
}

.faq-add-btn:hover {
    color: #ffffff;
	background-color: #021849;
}

.faq-response-question {
	color: #021849;
	background-color: #eef2fb;
	font-weight: 600;
	padding: 15px 10px;
}

.custom-admin-faq-collapse {
	/* color: #021849; */
	background-color: #f2f4f8;
	/* font-weight: 600; */
}