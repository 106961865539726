.card-campagne-progresse-price-container {
  display: flex;
  justify-content: space-between;
}

.fa-radio label {
  font-family: "Poppins";
  display: inline-block;
}
.fa-radio:hover,
.fa-radio label:hover {
  cursor: pointer;
}
.fa-radio {
  display: block;
  position: relative;
  font-weight: bold;
}

.fa-radio input[type="radio"],
.fa-checkbox input[type="checkbox"] {
  display: none;
}

.fa-radio input[type="radio"] + label:before {
  display: block;
  font-family: "FontAwesome";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 8px;
}

.fa-radio input[type="radio"] + label {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
  height: 86px;
  justify-content: center;
  display: flex;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 2.75676px 10.3378px rgba(0, 0, 0, 0.08);
}

.fa-radio .item {
  display: inline-block;
  margin-right: 10px;
  width: 100%;
  height: 86px;
  justify-content: center;
  display: flex;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 2.75676px 10.3378px rgba(0, 0, 0, 0.08);
}

/* ============= */
.fa-radio input[type="radio"] + label:before {
  font-family: "Font Awesome 5 Free";
  content: "\f111";
  position: absolute;
  bottom: 12px;
  left: 58px;
  color: #666666;
}
.fa-radio input[type="radio"]:checked + label:before {
  content: "\f192";
}

.fa-radio input[type="radio"]:checked + label.text-grey {
  background-color: #666666;
  color: #fff;
  border-color: #666666;
  width: 110px;
  height: 61px;
  justify-content: center;
  display: flex;
  align-items: center;
}

/* ============= */

.card-img-financer-campagne {
  margin-top: -20px;
  width: 33px;
  height: 33px;
}

.container-card-img-financer-campagne {
  display: flex;
  justify-content: center;
  gap: 10px;
  overflow: auto;
}
.container-card-img-financer-campagne::-webkit-scrollbar {
  display: none;
}

.card-img-detail-campagne,
.card-img-detail-campagne2 {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-details-campagne-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #000000;
}
.card-detail-campagne-progresse-percentage {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #757575;
}
.card-detail-campagne-progresse-price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0fbf56;
}
.card-detail-campagne-progresse-details {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #757575;
}
.card-detail-campagne-progresse-details-para {
  margin-top: -10px;
}
.card-detail-campagne-date-lancement {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: rgba(0, 0, 0, 0.5);
}
.campagne-detail-button-contribuer {
  background: #0fbf56;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #ffffff;
  padding: 15px 30px;
}
.campagne-detail-button-contribuer:hover {
  background: #fff;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #0fbf56;
  padding: 15px 30px;
  border: 1px solid #0fbf56;
}
.custom-card-detail-campagne {
  background: #ffffff;
  border: none;
}

.campagne-detail-projet-description-video {
  position: relative;
}
.campagne-detail-projet-description-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.campagne-detail-projet-description-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #0fbf56;
}
.campagne-detail-projet-description-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: #2d2d2d;
}

.campagne-detail-projet-description-porteur-obj {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.025em;
  color: #000;
}
.campagne-detail-projet-description-porteur,
.campagne-detail-projet-description-objectif {
  color: #0fbf56 !important;
}

.stepper-mission {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  background: none;
  border: none;
  padding: 12px;
}
.stepper-active-mission {
  background: #f3f3f4;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.campagne-stepper-buttons-container {
  display: flex;
  gap: 10px;
  overflow: auto;
}
.campagne-stepper-buttons-container::-webkit-scrollbar {
  display: none;
}

.campagne-detail-projet-para-aside {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
}

.campagne-detail-projet-card-commentaire {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  position: relative;
}

.card-img-detail-campagne-commentaire {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.campagne-detail-projet-card-commentaire-img {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.campagne-detail-projet-card-commentaire-author {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.campagne-detail-projet-card-commentaire-para {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6);
}
.campagne-detail-projet-card-commentaire-date {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.campagne-detail-projet-formulaire-input {
  border: 0.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  height: 50px;
  /* color: rgba(0, 0, 0, 0.6); */
}

.campagne-detail-projet-formulaire-buton {
  background: #021849;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  padding: 15px 20px;
}
.campagne-detail-projet-formulaire-buton:hover {
  border: 1px solid #021849;
  color: #021849;
  background: #fff;
  text-decoration: none;
}

.campagne-detail-projet-formulaire-commentaire {
  margin-top: 80px;
  margin-bottom: 30px;
}
.campagne-detail-projet-formulaire-faq,
.campagne-detail-projet-description-video {
  margin-top: 40px;
  margin-bottom: 20px;
}

.accordion-item-detail-projet {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.025em;
  color: #2d2d2d;
}

.campagne-faq-section {
  margin-bottom: 30px;
}
.campagne-detail-projet-faq-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.025em;
  color: #010101;
}
.campagne-detail-projet-faq-info-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: rgba(0, 0, 0, 0.6);
}

.campagne-detail-projet-formulaire-input-faq {
  border: 0.5px solid rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  height: 40px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* color: rgba(0, 0, 0, 0.6); */
}
.campagne-detail-projet-formulaire-buton-faq {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  background: #0fbf56;
  padding: 10px 20px;
}
.campagne-detail-projet-formulaire-buton-faq:hover {
  border: 1px solid #0fbf56;
  color: #0fbf56;
  background: #fff;
  text-decoration: none;
}

.accordion-button {
  padding: 1rem 0rem;
}

.card-container-financer-campagne-cb-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10.1136px;
  line-height: 15px;
  color: #000000;
}
.card-container-financer-campagne-cb {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.campagne-detail-projet-card-commentaire-action-btn-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}
.campagne-detail-projet-card-commentaire-action-btn-edit,
.campagne-detail-projet-card-commentaire-action-btn-delete {
  border: none;
  background: none;
}

@media (max-width: 768px) {
  .container-card-img-financer-campagne {
    flex-direction: row;
    justify-content: start;
    overflow: auto;
  }
  .container-card-img-financer-campagne::-webkit-scrollbar {
    display: none;
  }

  .campagne-detail-projet-card-commentaire {
    flex-direction: column;
    align-items: center;
  }
  .campagne-detail-projet-card-commentaire-author {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1440px) {
  .card-img-detail-campagne {
    max-width: 100%;
    height: 90%;
  }
}

/* ============== */

.card-img-detail-campagne {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 90% !important;
  height: 365px !important;
}


