.author-message-name {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #000000;
}
.hour-publish-message {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #000000;
}

.message-content {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: #404040;
}
.message-item {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    border-bottom: .1px solid  rgba(204, 204, 204, 0.6);
    margin-bottom: 1.5rem;
}
.form-admin-textarea {
	min-height: 250px !important;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	color: #000000;
}

.form-admin-textarea::placeholder {
	font-size: 14px;
	line-height: 20px;
	color: #8b8b8b;
}
.custom-avatar-message {
	width: 70px;
	height: 70px;
	border-radius: 50%;
}

@media (max-width: 425px) {
	.message-item {
		gap: 0rem;
	}
}
