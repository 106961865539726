.admin-donnateur-btn-voir-icon {
	color: #404040 !important;
}

.mad-donnateur-infos-perso-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0C3080;

}

.mad-donnateur-infos-perso-nom-container {
    display: flex;
    border-bottom: 1.5px solid #ECECEC;
    padding-bottom: 15px;
}

.mad-donnateur-infos-perso-nom-label,
.mad-donnateur-infos-perso-nom {
    width: 50%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: rgba(24, 24, 25, 0.9);
    overflow: auto;
}

.mad-donnateur-infos-perso-nom::-webkit-scrollbar {
    display: none;
} 

.mad-donnateur-infos-perso-historique-container {
    display: flex;
}

.mad-donnateur-infos-perso-date,
.mad-donnateur-infos-perso-projet,
.mad-donnateur-infos-perso-montant,
.mad-donnateur-infos-perso-date-header,
.mad-donnateur-infos-perso-projet-header,
.mad-donnateur-infos-perso-montant-header {
    width: 30%
}

.mad-donnateur-infos-perso-date-header,
.mad-donnateur-infos-perso-projet-header,
.mad-donnateur-infos-perso-montant-header
 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.4px;
    color: #22242C;
}
.mad-donnateur-infos-perso-date,
.mad-donnateur-infos-perso-projet,
.mad-donnateur-infos-perso-montant {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
}
.mad-donnateur-infos-perso-boutton-back {
    background: none;
    border: none;
    margin-bottom: 5px;
    margin-top: -15px;
}

.mad-donnateur-infos-perso-montant,
.mad-donnateur-infos-perso-montant-header {
text-align: center;
}

.mt-tableau-bord {
    padding-top: 85px;
    padding-left: 0px;
    padding-right: 30px;
}