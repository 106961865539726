.admin-page-container {
	margin-top: 3rem;
	width: 100%;
}
.admin-component-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 140%;
	color: #000000;
}
.tabs-container {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	padding: 0;
}

.tabs-container li {
	list-style: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 250;
	font-size: 16px;
	line-height: 140%;
	color: #2d3748;
	cursor: pointer;
}
li.tab-active-item {
	color: #021849;
	font-weight: 600;
}

/* Tabs 2 */
.open-modal-add-admin {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	background-color: transparent;
	border: none;
}

.admin-modal-title {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
}
.admin-modal-sous-title {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #000000;
}
.add-other-title-btn {
	width: 100%;
	background: rgba(204, 204, 204, 0.15);
	border-radius: 10px;
	border: none;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #8b8b8b;
	padding: 0.8rem 0;
}
