.tableau-de-bord-side-bar li {
	list-style: none;
    width: 100%;
    padding: 14px 0;
}
.sidebar-link {
	font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    padding-top: 25px;
    padding-bottom: 25px;
}

.sidebar-link:hover,
.active-link {
	color: #0fbf56;
}
.tableau-de-bord-side-bar ul {
	display: flex;
	overflow-x: scroll;
	overflow-y: hidden;
	gap: 2rem;
}
.padding-responsive {
	padding-top: 120px;
	/* padding-top: 90px; */
}

@media (max-width: 767px) {
    .padding-responsive {
        /* padding-top: 120px; */
        padding-top: 90px;
    }
}