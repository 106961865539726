.voir-btn-offre {
	color: #404040;
	width: 18px;
	height: auto;
}
.majjal-table-caption {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 18px;
	color: #000000;
}
.total-points-card {
	background: #ffffff;
	border: 1px solid #c4c4c4;
	border-radius: 10px;
	margin-bottom: 2rem;
	display: flex;
	justify-content: center;
}
.total-label {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	color: #000000;
}
.total-points {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-size: 35px;
	line-height: 24px;
	color: #0fbf56;
}
.total-points-signe {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 24px;
	color: #000000;
}
.share-point-btn {
	background: transparent;
	border: none;
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #0fbf56;
	display: flex;
	align-items: center;
	gap: 5px;
}
.table-header-style {
	font-family: "Poppins";
	font-style: normal;
	font-size: 14px;
	line-height: 21px;
	color: #000000;
}
