.auth-title {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	line-height: 42px;
	color: rgba(0, 0, 0, 0.7);
}
.auth-social-icon-maajjal-cardd {
	padding: 8px 25px;
	left: 701px;
	top: 299px;
	border: 1px solid;
	background: #ffffff;
	border-radius: 10px;
	width: 100%;
}
.auth-other-line {
	font-family: "Nunito";
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #030229;
	text-align: center;
}
.auth-other-line::before {
	content: "";
	width: 45%;
	height: 1px;
	background-color: rgba(3, 2, 41, 0.1);
	position: absolute;
	left: 0;
	top: 11px;
}
.auth-other-line::after {
	content: "";
	width: 45%;
	height: 1px;
	background-color: rgba(3, 2, 41, 0.1);
	position: absolute;
	right: 0;
	top: 11px;
}
.majjal-form-control::placeholder {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	color: #5a5a5a;
}
.border-fa-color {
	border-color: #385c8e80;
}
.border-google-color {
	border-color: #f44336;
}

.border-twit-color {
	border-color: #55acee80;
}
.border-inst-color {
	border-color: #8f33c180;
}
.empty-header {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	height: 100px;
}
.auth-row {
	background: #ffffff;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
}
.auth-panel {
	min-height: 705px;
	border-radius: 10px 0 0 10px;
	display: grid;
	place-items: center;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
}
.auth-panel-right {
	border-radius: 0 10px 10px 0 !important;
	background: #ffffff;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
}

.form-auth-check {
	width: 24px;
	height: 24px;
	margin: 0;
}
.form-auth-check:checked, .form-auth-check:focus {
	background-color: #021849;
	outline: none;
	box-shadow: none;
}
.remember-me {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: #000000;
}

.register-link {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.1em;
	color: rgba(0, 0, 0, 0.7);
	text-decoration: none;
}

.forget-password-link,
.auth-link-message {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #021849;
	text-decoration: none;
}
.register-link:hover,
.forget-password-link:hover {
	color: #021849;
}

.auth-identite-file {
	border: 1px solid #c4c4c4;
	border-radius: 10px;
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	color: #5a5a5a;
	padding: 0 1rem;
}

.auth-recuperation-pass-description {
	font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    color: rgba(0, 0, 0, 0.7);
}
.custom-auth-panel {
	min-height: 550px !important;
	border-radius: 10px 0 0 10px;
    display: grid;
    place-items: center;
    box-shadow: 5px 5px 15px rgb(0 0 0 / 5%);
}

.custom-auth-panel-right {
	border-radius: 0 10px 10px 0 !important;
	background: #ffffff;
	box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
}

.cni-img-preview {
	width: 70px;
	height: 50px;
}

.min-character-lowercase-ok,
.min-character-count-ok,
.min-character-uppercase-ok,
.min-character-number-ok,
.min-character-special-ok{
	color: #021849 !important;
	font-weight: 600 !important;
}

.pvt-li {
	font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #5a5a5a;
}

.password-validation-terms-title {
	font-family: "Raleway";
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
	font-weight: 500;
}

.img-preview-container {
	width: 100%;
	margin-top: -20px;
	margin-bottom: 15px;
}

.warning-asterix-content {
	position: relative;
}
.warning-asterix {
	position: absolute;
	top: 0px;
	right: 20px;
	color: red;
}
.avertissement-champs-obligatoire {
	margin-bottom: 10px;
	font-size: 12px;
	font-family: "Raleway";
    font-style: normal;
	display: flex;
	align-items: center;
}