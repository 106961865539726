.form-search-user {
	border: 1px solid #e1e1e1;
	border-radius: 50px;
	width: 100%;
}
.user-for-share-point:hover {
	background: #ecf4fe;
}
.user-for-share-point-infos h3,
.user-for-share-point-infos h4 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
}
.user-for-share-point-infos h3 {
	color: #000;
}

.user-for-share-point-infos h4 {
	color: rgba(0, 0, 0, 0.76);
}

.btn-cancel-share-point {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #c92e2e;
    background-color: transparent;
    border: none;
}

