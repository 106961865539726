.quisommesnous-page-couverture-container {
    margin-top: 87px;
    background: #E6E8ED;
    padding: 4em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.quisommesnous-page-couverture-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.025em;
    color: #021849;
}

.quisommesnous-page-content {
    padding: 50px 100px;
}

.quisommesnous-page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: -0.025em;
    color: #021134;
}
.quisommesnous-page-para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 165.2%;
    display: flex;
    align-items: center;
    letter-spacing: -0.025em;
    color: #737373;
}

.quisommesnous-page-section-nosvaleur,
.quisommesnous-page-section-notre-mission,
.quisommesnous-page-section-notre-equipe {
    margin-top: 50px;
}

.quisommesnous-page-title-notreequipe,
.username-card-container {
    text-align: center;
}

.img-card-quisommenous {
    width: 150px;
    height: 150px;
}
.quisommesnous-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 90%; */
    background: #FFFFFF;
    border: 1px solid #F3F3FA;
    border-radius: 4px;

    filter: drop-shadow(0px 4px 20px rgba(71, 100, 195, 0.1));
    padding: 30px 20px 100px 20px;
}

.username-card-title {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 21.248px;
    line-height: 28px;
    color: #091638;
}
.username-card-para {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #091638;
}

.username-card-container {
    margin-top: 20px;
}
.quisommesnous-card-container {
    margin: 50px 0px;
}
/* .quisommesnous-page-section-notre-mission {
    padding: ;
} */

.img-quisommenous {
    width: 390px;
    height: 390px;
}
.quisommesnous-nosvaleur-img-container,
.quisommesnous-nosvaleur-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.quisommesnous-nosvaleur-img-container2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

/* ================== CONDITIONS ET TERMES ================== */

.page-conditiond-terme-titre {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 250;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
}
.page-conditiond-terme-paragraphe {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 275;
    font-size: 16px;
    line-height: 209.9%;
    display: flex;
    align-items: center;
    color: #000000;
}

@media (max-width: 768px) {
    .quisommesnous-page-content {
        padding: 50px 10px;
    }

    .quisommesnous-page-section-notre-mission {
        display: flex;
        flex-direction: column-reverse;
    }

    .img-quisommenous {
        width: 270px;
        height: 270px;
    }

    .quisommesnous-page-couverture-title,
    .quisommesnous-page-title {
        font-size: 35px;
    }
    .quisommesnous-nosvaleur-img-container2 {
        justify-content: center;
    }
    .page-conditiond-terme-titre {
        font-size: 30px;
    }
}