.details-projet-tab-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.02);
    margin-top: 87px;
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;

}
.details-projet-tab-div1 {
    display: flex;
    gap: 40px;
}
.details-projet-tab-div2 {
    display: flex;
    gap: 10px;
    align-items: center;
}
.page-detail-projet-tab-icon {
    background: none;
    border: none;
    color: rgba(2, 24, 73, 0.3);
    font-size: 20px;
}

.mesprojets-detail-projet-titre {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #003344;
}

.mesprojets-detail-projet-titre-info {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.025em;
    color: #2D2D2D;
}
.mesprojets-detail-projet-categorie {
    display: flex;
    justify-content: flex-start;
    gap: 100px;
    margin-bottom: 20px;
}
.mesprojets-detail-projet-data-creation {
    display: flex;
    justify-content: flex-start;
    gap: 60px;
   
}

.mesprojets-detail-projet-progession-container {
    width: 75%;
}
.mesprojets-detail-projet-resume {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 20px;
}

.mesprojets-detail-projet-categorie1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}
.mesprojets-detail-projet-categorie2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
}

.mesprojets-detail-projet-description,
.mesprojets-detail-projet-info-sup {
    background: #FFFFFF;
}

.mesprojets-detail-projet-info-sup-tab-container {
    border-bottom: 1px solid  #EAEAEA;
    display: flex;
    overflow: auto;
    gap: 15px;
}
.mesprojets-detail-projet-info-sup-tab-container::-webkit-scrollbar {
    display: none;
}

.stepper-detail-projet1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(2, 24, 73, 0.3);
    padding-bottom: 10px;
    background: none;
    border: none;
}

.stepper-active-detail-projet1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(2, 24, 73, 0.8);
    border-bottom: 1px solid rgba(2, 24, 73, 0.63);
}

.stepper-detail-projet {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    background: none;
    border: none;
    padding-bottom: 10px;
}
.stepper-active-detail-mission {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #021849;
    border-bottom: 1px solid #021849;
}

.page-detail-projet-container {
    padding: 50px 50px 50px 50px;
    height: auto;
    min-height: 100vh;
}

.liste-don-table-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
}

.liste-don-table-name {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #171D33;

}
.liste-don-table-date {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: rgba(0, 0, 0, 0.7);
}
.liste-don-table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    padding: 15px 0px;
}
.mesprojet-details-projet-table-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.liste-don-table-prix {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #171D33;
}



.card-detail-campagne-progresse-percentage-dashboard {
    font-size: 20.9191px;
    line-height: 27px;
}
.card-detail-campagne-progresse-price-dashboard {
    font-size: 20.9191px;
    line-height: 27px;
}
.card-detail-campagne-progresse-details-para-dashboard {
    font-size: 16.0916px;
    line-height: 21px;
}

.react-bootstrap-table table {
    table-layout: auto;
  }


@media (max-width: 768px) {
    .mesprojets-detail-projet-progession-container {
        width: 100%;
    }

    .card-detail-campagne-progresse-percentage-dashboard {
        font-size: 13px;
        line-height: 17px;
    }
    .card-detail-campagne-progresse-price-dashboard {
        font-size: 13px;
        line-height: 20px;
    }
    .card-detail-campagne-progresse-details-para-dashboard {
        font-size: 12px;
        line-height: 16px;
    }

    .details-projet-tab-div1 {
        gap: 10px;
    }
    .details-projet-tab-div2 {
        gap: 5px;
    }
}

@media (max-width: 767px) {
    .details-projet-tab-container {
        margin-top: 0px;
        padding: 20px 0px 0px 0px;
    }
}