.voir-btn-offre {
	color: #404040;
	width: 18px;
	height: auto;
}
.majjal-table-caption {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 18px;
	color: #000000;
}
.my-custom-filter {
	display: inline-block;
	background: #f7f7f7;
	border-radius: 10px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 200;
	font-size: 14px;
	line-height: 17px;
	color: #252733;
}
.detail-offre-back-btn {
	background: transparent;
	border: none;
}
.detail-offre-title {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.6);
	padding-left: 0;
}
.infos-ligne {
	border-bottom: 1px solid rgba(196, 196, 196, 0.5);
	margin-bottom: 1rem;
}
.infos-ligne h5,
.infos-ligne h6 {
	font-family: "Poppins";
	font-style: normal;
	font-size: 14px;
	line-height: 21px;
	color: #5a5a5a;
	overflow: auto;
}
/* .infos-ligne h5, */
.infos-ligne h6::-webkit-scrollbar {
	display: none;
}

.img-info-produit {
	max-width: 400px;
	max-height: 400px;
}